import React from "react"
import { Link } from "gatsby"
import { BiMailSend, BiPhoneCall } from "react-icons/bi"

export default function Footer() {
  var date = new Date()
  var currentYear = date.getFullYear()
  return (
    <div className="footer">
      <div className="nav">
        <Link className="navbar-item" to="/">
          Home
        </Link>
        <Link className="navbar-item" to="/about">
          F.A.Q.
        </Link>
        <Link className="navbar-item" to="/contact">
          Contact
        </Link>
      </div>
      <div className="contact">
        <p>
          <BiMailSend style={{ marginBottom: -3 }} /> flyingfishweb@gmail.com
        </p>
        <p>
          <BiPhoneCall style={{ marginBottom: -3 }} /> 832-622-6336
        </p>
      </div>
      <p>
        Copyright © {currentYear} | Flying Fish Web Development | All Rights
        Reserved
      </p>
    </div>
  )
}
