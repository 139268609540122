import React from "react"
import { Helmet } from "react-helmet"

export default function MyHelmet() {
  return (
    <Helmet>
      <meta charSet="utf-8" />
      <title>
        Customizable, Modern, and Affordable Web Development | Flying Fish Web
      </title>
      <link rel="canonical" href="REPLACE THIS" />
    </Helmet>
  )
}
