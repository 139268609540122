import React from "react"
import { Link } from "gatsby"
import Logo from "../images/FlyingFish.jpg"
import DarkLogo from "../images/FlyingFishDark.png"
import Switch from "react-switch"
import { BiSun, BiMoon } from "react-icons/bi"

export default function Nav({
  backgroundColorProp,
  optionalHeader,
  isDark,
  setIsDark,
  currentPage,
}) {
  const defaultBackgroundColor = "#f2f8ff"

  const backgroundColor = backgroundColorProp || defaultBackgroundColor

  const onClickThemeToggle = () => {
    setIsDark(!isDark)
    if (typeof window !== "undefined") {
      localStorage.setItem("isDark", !isDark)
    }
  }

  return (
    <div style={{ background: `${isDark ? "#4e4e4e" : "#f9f9f9"}` }}>
      <div className="navbar-container">
        <Link to="/" className="image">
          <img src={isDark ? DarkLogo : Logo} alt="Logo" />
        </Link>
        <div className="nav">
          <Link
            className="navbar-item"
            to="/"
            style={{
              backgroundColor,
              fontWeight: `${currentPage === "home" ? "bold" : ""}`,
            }}
          >
            Home
          </Link>
          <Link
            className="navbar-item"
            to="/about"
            style={{
              backgroundColor,
              fontWeight: `${currentPage === "about" ? "bold" : ""}`,
            }}
          >
            F.A.Q.
          </Link>
          <Link
            className="navbar-item"
            to="/contact"
            style={{
              backgroundColor,
              fontWeight: `${currentPage === "contact" ? "bold" : ""}`,
            }}
          >
            Contact
          </Link>
          <div style={{ display: "flex", alignItems: "center" }}>
            <Switch
              checked={!isDark}
              onChange={() => onClickThemeToggle()}
              offColor="#312D37"
              onColor="#fff"
              handleDiameter={35}
              offHandleColor="#f9f9f9"
              onHandleColor="#312D37"
              width={90}
              uncheckedIcon={
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    height: "100%",
                    fontSize: 12,
                    fontWeight: 500,
                    color: "#ececec",
                    marginLeft: -45,
                    border: "2px solid #c7c7c7",
                    borderTopRightRadius: 10,
                    borderBottomRightRadius: 10,
                  }}
                >
                  <BiMoon style={{ marginLeft: 18, fontWeight: "bolder" }} />
                </div>
              }
              checkedIcon={
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    height: "100%",
                    fontSize: 12,
                    fontWeight: 500,
                    color: "#313131",
                    marginRight: -45,
                    border: "2px solid #a9a9a9",
                    borderTopLeftRadius: 10,
                    borderBottomLeftRadius: 10,
                  }}
                >
                  <BiSun style={{ marginLeft: -18 }} />
                </div>
              }
            />
          </div>
        </div>
      </div>
      <h1
        className="center-text"
        style={{ paddingBottom: 30, borderBottom: "2px solid #8c8c8c" }}
      >
        {optionalHeader}
      </h1>
    </div>
  )
}
